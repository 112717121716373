<template>
  <div class="cardlist">
    <div class="box" :style="styleObject">
      <div class="title">
        <h2><slot name="title">标题</slot></h2>
      </div>
      <div class="text">
        <slot name="content"> 这是内容</slot>
      </div>
      <div class="img">
        <img :src="require('../../assets/images/cardimg/' + styleObject.bgimg)">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    objStyle: {
      type: Object,
      default: () => {
        return {
          hoverbg: "#2d444f",
          bgcolor: "#2574db",
          bgimgUrl: "",
        };
      },
    },
  },
  computed: {
    styleObject() {
      return {
        "--background-color": this.objStyle.bgcolor,
        "bgimg": this.objStyle.bgimgUrl,
      };
    },
  },
};
</script>
<style lang="less" scoped>
.cardlist {
  flex: 1;
  justify-content: center;
  align-items: center;
  color: black;
}
.box {
  width: 100%;
  min-width: 150px;
  min-height: 350px;
  border: 1px solid var(--background-color);
  margin: 30px;
  padding: 19px;
  box-sizing: border-box;
  background: var(--background-color);
  background-image: url("../../assets/images/cardimg/card-bgimg-200x200.png");
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  .text {
    line-height: 25px;
    letter-spacing: 1px;
    padding-top: 10px;
    p {
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
  .title {
    text-align: center;
    h2 {
      font-weight: normal;
      position: relative;
    }
    h2::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -10px;
      right: 0;
      border-bottom: 1px solid black;
    }
  }

}
.img{
  position: absolute;
  // background:red;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  img{
    width: 100%;
    height: 100%;
    bottom: -40%;
    right: -40%;
    position: absolute;
  }
}
.box:hover {
  color: white;
  transform: scale(1.05);
}

@media screen and (max-width: 1000px) {
  .box {
    margin: 10px;
  }
}
</style>