<template>
  <div>
    <div class="container">
      <div class="descContainer descContainerRev">
        <div class="imgDiv imgDivA">
          <slot name="imglable"><img src="../../../assets/images/contentImgs/retailers.png" /></slot>
        </div>
        <div class="content contentA">
          <slot name="contentTitleH3"><h3>完善的服务系统</h3></slot>
          <slot name="contentTitleH1"><h1>物流查询系统</h1></slot>
          <slot name="contentSpan"><span
            >为企业小白提供海关编码查询系统，无需专业的报关知识及海关编码查询规类知识，即可为企业小白们快速查询到对应的产品海关编码，方便快捷，满足不同专业度的进出口企业。同时我们还提供了航空机场三字代码查询系统、全球海运港口中英文对照系统，为物流操作人解决汇总与查询的烦恼，提升操作专业度。</span></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
@import url('../../../assets/css/imgdes.less');

</style>