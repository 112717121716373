<template>
  <div class="container">
    <div class="banner">
      <img src="~images/contentImgs/banner-wood.svg" />
    </div>
    <div class="innerBox">
      <div class="desc">
        <h1>- 供应链服务 -</h1>
        <img src="~images/contentImgs/supply.png" />
        <p>
          供应链的核心企业以及与其相关的上下游企业看作一个整体,以核心企业为依托,<br />对供应链上下游企业提供的综合性金融产品和服务,从原材料采购,中间制造环节以及制成最终产品,最后通过销售渠道送达消费者手中,<br />供应商、制造商、分销商、零售商、消费者成为一个有机整体。提供灵活的金融、物流服务。整个过程中,资金充当了润滑剂,使整个过程运转得更加高效。
        </p>
      </div>
    </div>
    <div class="innerBox grayBg">
      <div>
        <h1>- 供应链物流解决方案 -</h1>
        <div class="cardlist">
          <card-list
            :objStyle="{
              bgcolor: '#2574db',
              bgimgUrl: 'card-ilo-hash.svg',
            }"
          >
            <div slot="title">融资服务</div>
            <div slot="content">
              货物在出具提单后，未抵达国内前的提单金融行为，为上下游提供灵活的金融服务，加速企业运作。
              <ul>
                <li>提单、现货融资</li>
                <li>信用证</li>
                <li>外汇</li>
              </ul>
            </div>
          </card-list>
          <card-list
            :objStyle="{
              bgcolor: '#ffa100',
              bgimgUrl: 'card-ilo-compass.svg',
            }"
          >
            <div slot="title">智慧通关</div>
            <div slot="content">
              优质进口资质，为企业提供快捷便利的通关、物流服务。结合对公路，铁路，航空等各类运输的资源融合，通过多式联运方式，高时效解决项目物流中的运输问题。
            </div>
          </card-list>
          <card-list
            :objStyle="{
              bgcolor: '#d09ce7',
              bgimgUrl: 'card-svg-fangshe.svg',
            }"
          >
            <div slot="title">仓储</div>
            <div slot="content">
              依托金融服务，为大综商品提供全套的配套服务，解决客户现货融资，货物融资销售等情况。打通整个金融、物流、融资销售服务。
            </div>
          </card-list>
          <!-- <card-list  :objStyle="{
              bgcolor: '#d09ce7',
              bgimgUrl: 'card-svg-fangshe.svg',
            }">
            <div slot="title">服务商品</div>
            <div slot="content">
              <p>大宗商品：木材、煤、铁矿等</p>
              <p>食品类：橄榄油、大米等</p>
              <p>预包装食品</p>
              <p>跨境电商货物</p>
              <p>其他普通货物进出口</p>
            </div>
          </card-list> -->
        </div>
      </div>
    </div>
    <div class="innerBox">
      <ldes-rimg>
        <h3 slot="revContentTitleH3">专注你我擅长的事</h3>
        <h1 slot="revContentTitleH1">供应链产业链</h1>
        <span slot="revContentSpan">丰富的供应链解决方案，提供供应链融资、进出口报关，物流、仓储、保税物流、国际快递、外汇等一系列综合服务，通过我们擅长的专业知识为你管理整个供应链，你只需专注于生产，就让我们的服务为你创造价值。</span>
        <img slot="revImglable" src="~images/contentImgs/logistics.jpg"/>
      </ldes-rimg>
      <limg-rdes>
         <h3 slot="contentTitleH3">打造智能化运作</h3>
        <h1 slot="contentTitleH1">智慧通关</h1>
        <span slot="contentSpan">企业多年经验累积，通过上百万次申报经验打造的智能化通关申报系统，依托数据经验积累，融入国家数据平台信息化，减少冗余出错，提升申报效率，企业的进口优质资质，保障外贸企业或进口商的快捷申报。</span>
        <img slot="imglable" src="~images/contentImgs/containerstoage.jpg"/>
      </limg-rdes>
    </div>
  </div>
</template>
<script>
import CardList from "components/plugin/cardlist.vue";
import LdesRimg from "components/plugin/imgdesc/ldes-rimg.vue";
import LimgRdes from "components/plugin/imgdesc/limg-rdes.vue";

export default {
  data() {
    return {};
  },
  components: { CardList, LdesRimg ,LimgRdes},
};
</script>
<style lang="less" scoped>
@import url("../assets/css/banner.less");
@import url("../assets/css/container.less");
.banner{
  background-color: #4479A9 !important;
  // background-image:linear-gradient(180deg,#628fa3 0%,#3e545d 51%,#4c281f 100%);
  img{
    height: 100% !important;
  }
}
.desc {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 600px;
  }
  p {
    text-align: center;
    font-size: 14px;
    color: #666;
    line-height: 25px;
    letter-spacing: 1px;
  }
}
.grayBg {
  background: #f5f5f5;
  background-image: url("../assets/images/cardimg/card-bgimg-200x200.png");
}
.cardlist {
  display: flex;
}

@media screen and (max-width: 768px) {
  .cardlist {
    display: flex;
    flex-direction: column;
  }
}
</style>