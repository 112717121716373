var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"innerBox grayBg"},[_c('div',[_c('h1',[_vm._v("- 供应链物流解决方案 -")]),_c('div',{staticClass:"cardlist"},[_c('card-list',{attrs:{"objStyle":{
            bgcolor: '#2574db',
            bgimgUrl: 'card-ilo-hash.svg',
          }}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("融资服务")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 货物在出具提单后，未抵达国内前的提单金融行为，为上下游提供灵活的金融服务，加速企业运作。 "),_c('ul',[_c('li',[_vm._v("提单、现货融资")]),_c('li',[_vm._v("信用证")]),_c('li',[_vm._v("外汇")])])])]),_c('card-list',{attrs:{"objStyle":{
            bgcolor: '#ffa100',
            bgimgUrl: 'card-ilo-compass.svg',
          }}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("智慧通关")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 优质进口资质，为企业提供快捷便利的通关、物流服务。结合对公路，铁路，航空等各类运输的资源融合，通过多式联运方式，高时效解决项目物流中的运输问题。 ")])]),_c('card-list',{attrs:{"objStyle":{
            bgcolor: '#d09ce7',
            bgimgUrl: 'card-svg-fangshe.svg',
          }}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("仓储")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 依托金融服务，为大综商品提供全套的配套服务，解决客户现货融资，货物融资销售等情况。打通整个金融、物流、融资销售服务。 ")])])],1)])]),_c('div',{staticClass:"innerBox"},[_c('ldes-rimg',[_c('h3',{attrs:{"slot":"revContentTitleH3"},slot:"revContentTitleH3"},[_vm._v("专注你我擅长的事")]),_c('h1',{attrs:{"slot":"revContentTitleH1"},slot:"revContentTitleH1"},[_vm._v("供应链产业链")]),_c('span',{attrs:{"slot":"revContentSpan"},slot:"revContentSpan"},[_vm._v("丰富的供应链解决方案，提供供应链融资、进出口报关，物流、仓储、保税物流、国际快递、外汇等一系列综合服务，通过我们擅长的专业知识为你管理整个供应链，你只需专注于生产，就让我们的服务为你创造价值。")]),_c('img',{attrs:{"slot":"revImglable","src":require("images/contentImgs/logistics.jpg")},slot:"revImglable"})]),_c('limg-rdes',[_c('h3',{attrs:{"slot":"contentTitleH3"},slot:"contentTitleH3"},[_vm._v("打造智能化运作")]),_c('h1',{attrs:{"slot":"contentTitleH1"},slot:"contentTitleH1"},[_vm._v("智慧通关")]),_c('span',{attrs:{"slot":"contentSpan"},slot:"contentSpan"},[_vm._v("企业多年经验累积，通过上百万次申报经验打造的智能化通关申报系统，依托数据经验积累，融入国家数据平台信息化，减少冗余出错，提升申报效率，企业的进口优质资质，保障外贸企业或进口商的快捷申报。")]),_c('img',{attrs:{"slot":"imglable","src":require("images/contentImgs/containerstoage.jpg")},slot:"imglable"})])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("images/contentImgs/banner-wood.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"innerBox"},[_c('div',{staticClass:"desc"},[_c('h1',[_vm._v("- 供应链服务 -")]),_c('img',{attrs:{"src":require("images/contentImgs/supply.png")}}),_c('p',[_vm._v(" 供应链的核心企业以及与其相关的上下游企业看作一个整体,以核心企业为依托,"),_c('br'),_vm._v("对供应链上下游企业提供的综合性金融产品和服务,从原材料采购,中间制造环节以及制成最终产品,最后通过销售渠道送达消费者手中,"),_c('br'),_vm._v("供应商、制造商、分销商、零售商、消费者成为一个有机整体。提供灵活的金融、物流服务。整个过程中,资金充当了润滑剂,使整个过程运转得更加高效。 ")])])])}]

export { render, staticRenderFns }